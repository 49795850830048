// Override default variables before the import
// $body-bg: #333;

// $enable-gradients: true;

// $font-size-base: 1.2rem;
$primary: #ffffff;
$body-bg: #000;
// $my-border-color: rgba(rgb(0, 88, 139), 0.875);
$my-border-color: rgb(0, 88, 139);
$my-border-width: 0.2em;
// $body-color: #ff8000;
$body-color: $primary;

// $component-active-color: $primary;

// $input-bg: #000;
$input-bg: rgba(#222, 0.95);
$input-disabled-bg: rgba(#444, 0.95);
$input-color: $primary;
$input-border-color: $primary;

$input-group-addon-bg: none;
$input-group-addon-border-color: $primary;

// $border-width: 1em;
// $border-color: $primary;

$list-group-bg: rgba(rgb(0, 25, 139), 0.5);

// $list-group-color: $primary !default;
$list-group-border-color: $my-border-color !default;
$list-group-border-width: $my-border-width;

$nav-tabs-border-color: $my-border-color;
$nav-tabs-border-width: $my-border-width;
$nav-tabs-link-hover-border-color: $primary;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-border-color: $my-border-color;

$dropdown-color: $primary;
$dropdown-bg: rgba(#222, 0.95);
// $dropdown-border-color: $primary
$dropdown-border-color: $my-border-color;
$dropdown-border-width: $my-border-width;
$dropdown-divider-bg: $my-border-color;
// $dropdown-divider-height: $my-border-width;
$dropdown-link-hover-bg: rgba($primary, 0.75);
$dropdown-header-color: $primary;

$modal-content-color: $primary;
// $modal-content-bg: rgba(#000, 0.5);
$modal-content-bg: rgba(#222, 0.95);
// $modal-content-border-color: rgba($primary, 0.75);
$modal-content-border-color: $my-border-color;
$modal-content-border-width: $my-border-width;

$table-border-color: $my-border-color;

// $font-family-base: "Kalam";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.btn-primary {
  background: rgba(rgb(0, 25, 139), 0.25);
  color: $primary;
}
.btn-xxl {
  padding: 0.5rem 2.5rem;
  font-size: 2rem;
  border-width: 0.2rem;
  border-radius: 2.5rem;
}
.btn-xxl-container {
  padding: 1em 0em;
  border-bottom: $my-border-width solid $primary;
}
.btn-primary:disabled {
  background: rgba(#444, 0.95);
  color: rgba($primary, 0.75);
}

.requested {
  background: rgba(#28a745, 0.75);
}
.played {
  background: rgba(#dc3545, 0.75);
}
.notIncluded {
  background: rgba(#007bff, 0.75);
}

.modal-header {
  border-color: $my-border-color;
}
.modal-footer {
  border-color: $my-border-color;
}
.close {
  color: $primary;
  text-shadow: none;
  opacity: 1;
}
.close:hover {
  color: $primary;
  text-shadow: none;
  opacity: 1;
}
.nav-tabs {
  margin-bottom: 0.2em;
}
.nav-tabs .nav-link.active {
  border-bottom: none;
  background-color: rgba(#222, 0.95);
}
.hightlightButton {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes pulse {
  from {
  }
  to {
    color: #000;
    background-color: $primary;
  }
}
.list-group-item-button {
  background: "none";
  border: "none" !important;
}
// .stream-iframe-container-div {
//   padding-bottom: 56.25%;
//   position: relative;
//   display: block;
//   width: 100%;
// }
.pledge-disclaimer {
  height: 5em;
  overflow-y: scroll;
}
.pledge-disclaimer-container {
  border: $my-border-width solid $my-border-color;
  margin-bottom: 0.5em;
  padding: 0.5em;
}
.user-request-item {
  display: flex;
  margin: 0 0 0 0;
  padding: 0;
  white-space: nowrap;
}
