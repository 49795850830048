body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1000000;
  background: url("./images/ras_bg_512.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  font-family: "Neucha";
  /* font-family: "comic sans ms"; */
  /* background: #000024; */
  /* background-image: url("./images/Crowd-Background-01.jpg"); */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  /* background-position: center top; */
  /* background-size: 100%; */
  margin: 0.5em;
}

@media screen and (max-width: 700px) {
  /* Specific to this particular image */
  body:before {
    background-image: url("./images/ras_bg_tall.png");
  }
}
